<template>
    <div class="add-new-delivery">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.projectCreate')"
            :title="$t('messages.projectCreate')"
            @submitPressed="submitPressed"
        />
        <project-form :projectObject="project" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ProjectForm from './components/ProjectForm.vue'

export default {
    components: {
        TitleBar,
        ProjectForm
    },
    data() {
        return {
            action: null,
            project: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
